import React, { ButtonHTMLAttributes } from "react";
import styled, { CSSProperties } from "styled-components";
import { Loader, LoaderProps } from "./Loader";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  text: string;
  textColor?: string;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  size?: "SMALL" | "MEDIUM" | "LARGE";
  style?: CSSProperties;
  disabled?: boolean;
  fullWidth?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  loader?: LoaderProps;
  onClick: () => void;
}

const buttonSizes = {
  LARGE: {
    fontSize: "20px",
    height: "48px",
    padding: "5px 24px",
  },
  MEDIUM: {
    fontSize: "16px",
    height: "36px",
    padding: "4px 20px",
  },
  SMALL: {
    fontSize: "14px",
    height: "28px",
    padding: "3px 16px",
  },
};

export const Button = ({
  // theme = "light", // TODO hjelp, hva gjør jeg med themeing?
  loading,
  text,
  backgroundColor = "white",
  textColor = "black",
  border = "1px solid black",
  borderRadius = "4px",
  fullWidth = false,
  size = "MEDIUM",
  disabled: formInvalid = false,
  iconLeft,
  iconRight,
  style,
  loader,
  onClick,
}: Props): JSX.Element => {
  const disabled = formInvalid || loading;

  return (
    <StyledButton
      $textColor={textColor}
      $backgroundColor={backgroundColor}
      $border={border}
      $borderRadius={borderRadius}
      style={{ ...buttonSizes[size], ...style }}
      disabled={disabled}
      $fullWidth={fullWidth}
      $hasIcon={!!iconLeft || !!iconRight}
      onClick={onClick}
    >
      {iconLeft}
      {loading ? <Loader size="SMALL" color={textColor} {...loader} /> : text}
      {iconRight}
    </StyledButton>
  );
};

interface StyledButtonProps {
  // TODO må ha "BaseProps" osv
  $textColor: string;
  $backgroundColor: string;
  $border: string;
  $borderRadius: string;
  $hasIcon: boolean;
  $fullWidth: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  border: ${(props) => props.$border};
  border-radius: ${(props) => props.$borderRadius};

  color: ${(props) => props.$textColor};
  background-color: ${(props) => props.$backgroundColor};

  cursor: pointer;

  ${(props) =>
    props.$hasIcon &&
    `
      justify-content: space-between;
      display: flex;
      align-items: center;
    `}

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;
      cursor: default;
    `}

  ${(props) =>
    props.$fullWidth &&
    `
      width: 100%;
    `}
`;
