import React, { HTMLInputTypeAttribute, InputHTMLAttributes } from "react";
import styled, { CSSProperties } from "styled-components";
import { Label, LabelProps } from "./Label";

export interface Option {
  value: string;
  label?: string;
}

interface Props {
  id: string;
  focus?: boolean;
  label?: LabelProps;
  initialValue?: string;
  disabled?: boolean;
  style?: CSSProperties;
  options: Option[];
  optionsStyle?: CSSProperties;
  onChange: (newValue: string) => void;
  props?: InputHTMLAttributes<HTMLInputTypeAttribute>;
}

export const Select = ({
  id,
  label,
  focus = false,
  disabled = false,
  style,
  options,
  optionsStyle,
  onChange,
}: Props): JSX.Element => {

  const select = (
    <StyledSelect
      id={id}
      style={style}
      disabled={disabled}
      autoFocus={focus}
      onChange={(event: any) => onChange(event.target.value)}
    >
      {options?.map(option => {return <option style={optionsStyle} key={option.value} value={option.value}>{option.label ?? option.value}</option>})}
    </StyledSelect>
  );

  return (
    <SelectContainer>
      {label ? (
        <Label
          for={id}
          focusStyle={label.focusStyle}
          text={label.text}
          style={label.style}
        >
          {select}
         </Label>
       ) : (
         select
       )}
     </SelectContainer>
  );
};

const SelectContainer = styled.div`
  display: grid;
  grid-template-areas:
    "label"
    "input";
  width: 100%;
`;

const StyledSelect = styled.select`
  display: flex;
  align-items: center;
  grid-area: input;
`;
