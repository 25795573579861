import { useQuery } from "@apollo/client";
import { Col, Loader, Spacing, TypographyVariant } from "@hark-tech/components";
import { DateTime } from "luxon";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { ErrorText, SectionDivider, Typography } from "../../components";
import {
  GetHourlyUsageDocument,
  Report,
} from "../../generated/graphql/graphql";
import { useAlert } from "../../providers";
import { useTheme } from "../../theme/ThemeProvider";

interface HourlyUsageProps {
  deviceId: string;
}

const LIMIT_INVALID_HIGH = 100000;
const LIMIT_INVALID_LOW = 0;

export const HourlyUsage = ({ deviceId }: HourlyUsageProps) => {
  const theme = useTheme();
  const { limitInWatts } = useAlert();

  const endOfCurrentHour = DateTime.now().endOf("hour");
  const startOfHourFiveHoursAgo = DateTime.now()
    .startOf("hour")
    .minus({ hours: theme.numberOfHours - 1 });

  const { loading, data, error } = useQuery(GetHourlyUsageDocument, {
    variables: {
      deviceId: deviceId,
      from: startOfHourFiveHoursAgo.toString(),
      to: endOfCurrentHour.toString(),
    },
  });

  if (!data) {
    if (!loading || error) {
      return <ErrorText text="Noe gikk galt." />;
    } else {
      return <Loader size="MEDIUM" />;
    }
  } else if (data.reports.length === 0) {
    return <div />;
  } else {
    const consumptionByHour = createConsumptionByHour(data.reports);

    return (
      <Col $childSpacing={Spacing.MEDIUM} $marginTop={Spacing.LARGE} style={{flexGrow: 1}}>
        <SectionDivider />

        <Typography
          variant={TypographyVariant.HEADER}
          emphasized
          text={`Totalforbruk siste ${theme.numberOfHours} timer`}
        />

        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={consumptionByHour}>
            <defs>
              <linearGradient
                id="aboveLimitGradients"
                gradientTransform="rotate(90)"
              >
                <stop
                  offset="0%"
                  stopColor={theme.properties.colors.danger[300]}
                />
                <stop
                  offset="100%"
                  stopColor={theme.properties.colors.secondary[500]}
                />
              </linearGradient>
            </defs>

            <Bar dataKey="consumption" shape={<RoundedBar />}>
              <LabelList
                position={"top"}
                valueAccessor={(entry: ConsumptionByHour) =>
                  entry.consumption.toFixed(1).replace(".", ",")
                }
                fontWeight={900}
                fill={theme.properties.colors.text[500]}
              />
              {consumptionByHour.map((entry, index) => (
                <Cell
                  fill={
                    limitInWatts && entry.consumption > limitInWatts / 1000
                      ? "url(#aboveLimitGradients)"
                      : undefined
                  }
                  key={`cell-${index}`}
                />
              ))}
            </Bar>

            {limitInWatts && (
              <ReferenceLine
                y={limitInWatts / 1000}
                stroke={theme.properties.colors.text[500]}
                strokeDasharray={"3, 3"}
                strokeWidth={2}
              />
            )}

            <YAxis type="number" scale="linear" domain={[0, (dataMax: number) => (limitInWatts && limitInWatts/1000 > dataMax) ? Math.ceil(limitInWatts/1000) :(Math.ceil(dataMax))]} hide tickCount={4} />

            <XAxis
              dataKey={"startTime"}
              tickLine={false}
              axisLine={false}
              fontWeight={900}
              fontSize={12}
              fill={theme.properties.colors.text[500]}
              scale="band"
            />

            <CartesianGrid vertical={false}  />
          </ComposedChart>
        </ResponsiveContainer>
      </Col>
    );
  }
};

interface ConsumptionByHour {
  startTime: string;
  consumption: number;
}

function createConsumptionByHour(reports: Report[]): ConsumptionByHour[] {
  const consumptionByHour: ConsumptionByHour[] = [];

  reports.forEach((report, hourIndex) => {
    let consumption = 0;

    function measumentConsumptionIsInvalid(
      consumption: number | null | undefined
    ) {
      return (
        consumption == null ||
        consumption > LIMIT_INVALID_HIGH ||
        consumption <= LIMIT_INVALID_LOW
      );
    }

    const currentHourConsumptionIsInvalid = measumentConsumptionIsInvalid(
      report.activeEnergyImported
    );
    /* For some hours, we might not get any reported active energy from the HAN. 
    If that's the case, the next hour will report the active energy for both hours, 
    resulting in an unnaturally high consumption. Therefore, we  rather display the 
    average hourly consumption for both the "empty" hour and the following hour. */
    const previousHourConsumptionIsInvalid =
      hourIndex > 0 &&
      measumentConsumptionIsInvalid(
        reports[hourIndex - 1].activeEnergyImported
      );

    if (currentHourConsumptionIsInvalid || previousHourConsumptionIsInvalid) {
      consumption = report.averageActivePowerImport || 0;

      const reportIsForCurrentHour =
        DateTime.now().diff(DateTime.fromISO(report.from), "hours").hours < 1;

      if (reportIsForCurrentHour && report.averageActivePowerImport) {
        const minuteOfHour = new Date().getMinutes();
        consumption = (report.averageActivePowerImport / 60) * minuteOfHour;
      }
    } else {
      consumption = report.activeEnergyImported || 0;
    }
    if (consumption) {
      consumption /= 1000;
    }

    const { hour } = DateTime.fromISO(report.from);
    const parsedHour = `${hour < 10 ? "0" + hour.toString() : hour.toString()}`;

    consumptionByHour.push({
      consumption,
      startTime: `${parsedHour}:00`,
    });
  });

  return consumptionByHour;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RoundedBar = (props: any) => {
  const theme = useTheme();
  const { x, y, fill, width, height } = props;

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      rx={4}
      stroke="none"
      fill={fill ? fill : theme.properties.colors.secondary[500]}
    />
  );
};
