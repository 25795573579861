import { Checkbox, Row } from "@hark-tech/components";
import { useTheme } from "../theme/ThemeProvider";
import { Loader } from "./Loader";

interface Props {
  label: string;
  id: string;
  loading?: boolean;
  checked: boolean;
  onClick: () => void;
}

export const CheckboxListEntry = ({
  label,
  id,
  loading,
  checked,
  onClick,
}: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <Row
      style={{
        alignItems: "center",
        backgroundColor: theme.properties.colors.text["200"],
        borderRadius: "4px",
        display: "grid",
        height: "40px",
        paddingLeft: "8px",
        width: "100%",
      }}
    >
      {loading ? <Loader size="SMALL" centerVertically /> : 
        <Checkbox
          checked={checked}
          id={id}
          uncheckedOpacity
          iconSize="24"
          checkedCheckbox={{ color: theme.properties.colors.primary["500"] }}
          uncheckedCheckbox={{ color: theme.properties.colors.modalBackground["500"] }}
          label={{
            style: { color: theme.properties.colors.text["500"], fontSize: 14, fontWeight: "900" },
            text: label,
          }}
          setChecked={onClick}
        />
      }
    </Row>
  );
};
