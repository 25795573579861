const HelgelandLogo = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_821_2685)">
        <path
          d="M3.27004 8.23387C5.74723 7.821 6.79816 7.78347 7.39869 6.93897C8.31825 5.64408 7.64265 3.99261 7.64265 3.99261C7.64265 3.99261 7.19226 5.53148 3.4014 5.88804C1.43091 6.07571 0.0797129 7.7647 0.267379 9.49123C0.304912 9.77273 0.379979 10.0355 0.473812 10.2982C1.07434 9.35986 2.10651 8.42153 3.27004 8.23387Z"
          fill="#64A70B"
        />
        <path
          d="M6.70437 19.9442C6.70437 17.4107 6.83573 16.341 6.10384 15.6091C4.97784 14.4831 3.27008 14.896 3.27008 14.896C3.27008 14.896 4.69634 15.5904 4.43361 19.4375C4.30224 21.4268 5.72851 23.0595 7.45503 23.1533C7.73653 23.1533 7.99926 23.1533 8.28076 23.0783C7.4738 22.3276 6.72314 21.1453 6.72314 19.9442H6.70437Z"
          fill="white"
        />
        <path
          d="M12.8973 21.408C11.2834 19.4751 10.7016 18.5743 9.68824 18.4804C8.11184 18.3491 7.06091 19.7753 7.06091 19.7753C7.06091 19.7753 8.59977 19.3812 10.833 22.4965C11.9965 24.1104 14.1172 24.4482 15.5059 23.3973C15.7311 23.2284 15.9187 23.022 16.0876 22.8155C14.9992 22.778 13.6668 22.3464 12.8973 21.4268V21.408Z"
          fill="white"
        />
        <path
          d="M18.5836 18.518C16.1252 18.0676 15.1118 17.7485 14.2673 18.3491C12.9724 19.2686 13.0662 21.0515 13.0662 21.0515C13.0662 21.0515 14.0045 19.7566 17.6828 20.6949C19.597 21.1828 21.4361 20.0568 21.8302 18.3491C21.8865 18.0863 21.9053 17.8048 21.9053 17.5233C21.042 18.1989 19.7471 18.7244 18.5836 18.518Z"
          fill="white"
        />
        <path
          d="M21.0983 12.6253C18.9214 13.8826 17.9456 14.2955 17.6828 15.2901C17.27 16.829 18.471 18.1426 18.471 18.1426C18.471 18.1426 18.3584 16.5475 21.7927 14.8772C23.5755 14.014 24.2699 11.9497 23.4817 10.392C23.3503 10.1481 23.2002 9.92286 23.0125 9.69766C22.7686 10.7861 22.1305 12.0247 21.0983 12.6253Z"
          fill="white"
        />
        <path
          d="M19.2968 6.45104C18.4335 8.81563 17.9456 9.79149 18.3772 10.7111C19.034 12.1561 20.7981 12.3813 20.7981 12.3813C20.7981 12.3813 19.6909 11.2178 21.2672 7.7084C22.0742 5.88804 21.3048 3.86124 19.7284 3.16688C19.4844 3.05428 19.2029 2.97921 18.9402 2.94168C19.4469 3.91754 19.7284 5.30627 19.3155 6.43227L19.2968 6.45104Z"
          fill="white"
        />
        <path
          d="M14.0046 2.90415C14.8491 5.28751 15.0743 6.33844 15.9938 6.77007C17.4201 7.44567 18.9026 6.48857 18.9026 6.48857C18.9026 6.48857 17.3263 6.31967 16.3129 2.60389C15.7874 0.670926 13.9107 -0.380003 12.2593 0.126695C11.9965 0.201761 11.7526 0.333128 11.5086 0.464494C12.522 0.896126 13.6105 1.75939 14.0046 2.88539V2.90415Z"
          fill="white"
        />
        <path
          d="M7.6802 3.59852C9.83836 4.89342 10.6829 5.53148 11.6775 5.28752C13.1976 4.87465 13.723 3.18566 13.723 3.18566C13.723 3.18566 12.4094 4.06769 9.25659 1.89076C7.6239 0.764765 5.50327 1.15886 4.56494 2.62266C4.41481 2.84786 4.30221 3.11059 4.20837 3.37332C5.2593 3.05429 6.64803 2.99799 7.6802 3.61729V3.59852Z"
          fill="white"
        />
        <path
          d="M2.83844 14.652C4.47114 12.7378 5.25933 12.0059 5.18427 10.9738C5.0529 9.37862 3.49527 8.55289 3.49527 8.55289C3.49527 8.55289 4.13334 10.0167 1.44971 12.7566C0.0609852 14.1641 0.0797518 16.3598 1.33711 17.5609C1.54355 17.7485 1.76875 17.9174 2.01271 18.0488C1.88135 16.9416 2.08778 15.5528 2.85721 14.652H2.83844Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_821_2685">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HelgelandLogo