import { Theme } from "../theme/ThemeType";

export enum TextColor {
  TEXT = "TEXT",
  LIGHT_TEXT = "LIGHT_TEXT",
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  SUCCESS = "SUCCESS",
  LIGHT_SUCCESS = "LIGHT_SUCCESS",
  DANGER = "DANGER",
  LIGHT_DANGER = "LIGHT_DANGER",
  WARNING = "WARNING",
}

export interface VariantProps {
  $textColor: TextColor;
  theme: Theme;
}

export const propsToTextColor = (props: VariantProps) => {
  switch (props.$textColor) {
    case TextColor.PRIMARY:
      return props.theme.properties.colors.primary[500];
    case TextColor.SECONDARY:
      return props.theme.properties.colors.secondary[500];
    case TextColor.TEXT:
      return props.theme.properties.colors.text[500];
    case TextColor.LIGHT_TEXT:
      return props.theme.properties.colors.text[300];
    case TextColor.DANGER:
      return props.theme.properties.colors.danger[500];
    case TextColor.LIGHT_DANGER:
      return props.theme.properties.colors.danger[300];
    case TextColor.SUCCESS:
      return props.theme.properties.colors.success[500];
    case TextColor.LIGHT_SUCCESS:
      return props.theme.properties.colors.success[300];
    case TextColor.WARNING:
      return props.theme.properties.colors.warning[500];
  }
};
