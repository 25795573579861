/* eslint-disable @typescript-eslint/naming-convention */
// import { DefaultTheme } from "styled-components";
import { Theme, ThemeType } from "./ThemeType";

export const LightTheme: Theme = {
  borderRadius: "16px",
  numberOfHours: 5,
  properties: {
    borderRadius: "16px",
    colors: {
      background: {
        "300": "rgba(0, 0, 0, 0.15)",
        "500": "rgba(0, 0, 0, 0.05)",
      },

      danger: {
        "300": "rgba(253,155,152, 0.25)",
        "500": "rgba(253,155,118, 1)",
      },

      modalBackground: {
        "500": "#ffffff",
      },

      primary: {
        "500": "rgba(0, 48, 87, 1)",
      },

      secondary: {
        "500": "rgba(193, 220, 157, 1)",
      },

      success: {
        "300": "rgba(60, 211, 157, 0.5)",
        "500": "rgba(60, 211, 157, 1)",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "900": "#1D8969",
      },

      text: {
        "100": "rgba(68, 68, 68, 0.0375)",
        "200": "rgba(68, 68, 68, 0.6)",
        "300": "rgba(68, 68, 68, 0.5)",
        "500": "rgba(68, 68, 68, 1)",
      },

      warning: {
        "500": "rgba(255,231,171,1)",
      },
    },
    type: ThemeType.LIGHT,
  },
};
