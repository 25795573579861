import { useTheme } from "../../../theme/ThemeProvider";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const CloseIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme })
    : theme.properties.colors.text[500];

  return (
    <path
      d="M13.3333 0L16 2.66667L10.6667 8L16 13.3333L13.3333 16L8 10.6667L2.66667 16L0 13.3333L5.33333 8L0 2.66667L2.66667 0L8 5.33333L13.3333 0Z"
      fill={fill}
    />
  );
};
