import { PropsWithChildren } from "react";
import { styled } from "styled-components";
import { useTheme } from "../theme/ThemeProvider";

interface CardProps {
  backgroundColor?: string;
}


export const Card = (props: PropsWithChildren<CardProps>): JSX.Element => {
  const { properties: theme, borderRadius} = useTheme();
  return (
    <StyledCard backgroundColor={props.backgroundColor} borderRadius={borderRadius} theme={theme}>{props.children}</StyledCard>
  );
};

interface StyledCardProps {
  backgroundColor?: string;
  borderRadius: string;
}

const StyledCard = styled.div<StyledCardProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: ${props => props.borderRadius ?? "16px"}; 
  background-color: ${props => props.backgroundColor ?? props.theme.colors.secondary["500"]}
`

