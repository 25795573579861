import { useTheme } from "../../../theme/ThemeProvider";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const EditIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme })
    : theme.properties.colors.text[500];

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_684_12114)">
        <path
          d="M0 9.40533V11.625C0 11.8321 0.167893 12 0.375 12H2.59467C2.92876 12 3.09607 11.5961 2.85984 11.3598L0.640165 9.14017C0.403928 8.90393 0 9.07124 0 9.40533Z"
          fill={fill}
        />
        <path
          d="M3.4626 10.4626L1.5374 8.5374C1.2406 8.2406 1.2406 7.7594 1.5374 7.4626L8.4626 0.537401C8.7594 0.240603 9.2406 0.240603 9.5374 0.537401L11.4626 2.4626C11.7594 2.7594 11.7594 3.2406 11.4626 3.5374L4.5374 10.4626C4.2406 10.7594 3.7594 10.7594 3.4626 10.4626Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_684_12114">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
