import {
  AlertSetting as GqlAlertSetting,
  EnergyBiddingZone as GqlEnergyBiddingZone,
  NotificationChannel as GqlNotificationChannel,
} from "../generated/graphql/graphql";

export const gqlAlertSettingToAlertSetting = (setting: GqlAlertSetting): AlertSetting => {
  if (setting.__typename === undefined) {
    throw new Error(
      "Backend returned undefined for __typename. This is not handled front end"
    );
  }

  switch (setting.__typename) {
    case "PriceWarningAlertSetting":
      return {
        alertType: AlertType.PRICE_WARNING,
        energyBiddingZone: graphqlEnergyBiddingZoneToEnergyBiddingZone(
          setting.energyBiddingZone
        ),
        id: setting.id,
        notificationChannels: setting.notificationChannels.map(
          gqlNotificationChannelToNotificationChannel
        ),
      };

    case "HourlyConsumptionLimitEstimationWarningAlertSetting":
      return {
        alertType: AlertType.HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING,
        deviceId: setting.deviceId,
        id: setting.id,
        limitInWatts: setting.limitInWatts,
        notificationChannels: setting.notificationChannels.map(
          gqlNotificationChannelToNotificationChannel
        ),
      };

    case "EstimatedHourlyActiveEnergyLimitWarningAlertSetting":
      return {
        alertType: AlertType.ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING,
        deviceId: setting.deviceId,
        id: setting.id,
        limitInWattHours: setting.limitInWattHours,
        notificationChannels: setting.notificationChannels.map(
          gqlNotificationChannelToNotificationChannel
        ),
      };
    default:
      throw new Error(
        "Backend could not recognize alertsetting __typename. This is not handled front end"
      );
  }
}


interface AlertSettingBase {
  alertType: AlertType;
  notificationChannels: NotificationChannel[];
  id: string;
}

export interface PriceWarningAlertSetting extends AlertSettingBase {
  alertType: AlertType.PRICE_WARNING;
  energyBiddingZone: EnergyBiddingZone;
}

export interface HourlyConsumptionLimitEstimationAlertSetting
  extends AlertSettingBase {
  alertType: AlertType.HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING;
  deviceId: string;
  limitInWatts: number;
}

export interface EstimatedHourlyActiveEnergyLimitWarningAlertSetting
  extends AlertSettingBase {
  alertType: AlertType.ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING;
  deviceId: string;
  limitInWattHours: number;
}


export type AlertSetting =
  | PriceWarningAlertSetting
  | HourlyConsumptionLimitEstimationAlertSetting
  | EstimatedHourlyActiveEnergyLimitWarningAlertSetting;

export enum AlertType {
  PRICE_WARNING = "PRICE_WARNING",
  HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING = "HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING",
  ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING = "ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING",
}

export enum EnergyBiddingZone {
  NO1 = "NO1",
  NO2 = "NO2",
  NO3 = "NO3",
  NO4 = "NO4",
  NO5 = "NO5",
}

export type NotificationChannelType =
  | "BRIGHT_NOTIFICATION"
  | "PUSH_NOTIFICATION"
  | "INTERMEDIARY_WEBHOOK";

interface IntermediaryWebhookNotificationChannel {
  type: "INTERMEDIARY_WEBHOOK";
}

interface PushNotificationChannel {
  type: "PUSH_NOTIFICATION";
  forUserWithId: string;
}

interface BrightNotificationChannel {
  type: "BRIGHT_NOTIFICATION";
  forUserWithId: string;
}

export type NotificationChannel =
  | BrightNotificationChannel
  | PushNotificationChannel
  | IntermediaryWebhookNotificationChannel;

function graphqlEnergyBiddingZoneToEnergyBiddingZone(
  zone: GqlEnergyBiddingZone
): EnergyBiddingZone {
  switch (zone) {
    case "NO1":
    default:
      return EnergyBiddingZone.NO1;
    case "NO2":
      return EnergyBiddingZone.NO2;
    case "NO3":
      return EnergyBiddingZone.NO3;
    case "NO4":
      return EnergyBiddingZone.NO4;
    case "NO5":
      return EnergyBiddingZone.NO5;
  }
}

const gqlNotificationChannelToNotificationChannel = (
  gqlNotificationChannel: GqlNotificationChannel
): NotificationChannel => {
  switch (gqlNotificationChannel.__typename) {
    case "PushNotificationChannel":
      return {
        forUserWithId: gqlNotificationChannel.forUserWithId,
        type: "PUSH_NOTIFICATION",
      };
    case "BrightNotificationChannel":
      return {
        forUserWithId: gqlNotificationChannel.forUserWithId,
        type: "BRIGHT_NOTIFICATION",
      };
  }

  return {
    type: "INTERMEDIARY_WEBHOOK",
  };
};
