import { DefaultTheme } from "styled-components";

export enum ThemeType {
  DARK = "DARK",
  LIGHT = "LIGHT",
  HELGELAND = "HELGELAND",
  TINN = "TINN"
}

export interface Theme {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  borderRadius: string;
  orderUrl?: string;
  properties: DefaultTheme;
  numberOfHours: number;
}
