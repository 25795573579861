import { Routes as ReactRoutes, Route } from "react-router-dom";
import { Overview } from "./Overview";

export const Routes = (): JSX.Element => {
  return (
    <ReactRoutes>
      <Route path={RouteMap.base} element={<Overview />} />
    </ReactRoutes>
  );
};

export const RouteMap = {
  base: "/",
};
