import { ApolloError, useQuery } from "@apollo/client";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useState
} from "react";
import { NoHarkUnit } from "../auth/no-auth";
import { MyDevicesDocument } from "../generated/graphql/graphql";

export interface DeviceContextValues {
  deviceId?: string;
  devices: {deviceId: string; deviceName: string;}[];
  error?: ApolloError;
  loading?: boolean;
  setDeviceId: (deviceId: string) => void;
}

const DeviceContext = createContext<DeviceContextValues | undefined>(undefined);

export const DeviceProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [deviceId, setDeviceId] = useState<string>();
  const [devices, setDevices] = useState<{deviceId: string; deviceName: string;}[]>([]);

  const { error, loading } = useQuery(MyDevicesDocument, {
    onCompleted: (data) => {
      if (data.myDevices.length > 0) {
        const activeDevices = data.myDevices.filter(device => device.activation?.status === "ACTIVATED");
        setDeviceId(activeDevices[0].deviceId)
        setDevices(activeDevices.map(device => ({deviceId: device.deviceId, deviceName: device.deviceName ?? device.deviceId})));
      }
    }
  });

  if (!deviceId && !loading) {
    return <NoHarkUnit />
  }

  return (
    <DeviceContext.Provider value={{ deviceId, devices, error, loading, setDeviceId }}>
      <>{children}</>
    </DeviceContext.Provider>
  );
};

export const useDevice = (): DeviceContextValues => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error(`useDevice must be called inside an AlertProvider`);
  }

  return context;
};

