import { Spacing } from "@hark-tech/components";
import styled from "styled-components";
import { NoHarkUnit } from "../auth/no-auth";
import { Loader, Select, Typography } from "../components";
import { useDevice } from "../providers";
import { ScreenWrapper } from "./ScreenWrapper";
import { AddOrUpdateNotificationSetting } from "./add-or-update-notification";
import { HourlyUsage } from "./hourly-usage";
import { AlertBanner } from "./notifications";

export const Overview = (): JSX.Element => {
  const {deviceId, devices, setDeviceId, error, loading} = useDevice();

  if (!deviceId && !loading) {
    return <NoHarkUnit />
  }

  return (
    <ScreenWrapper
      childSpacing={Spacing.MEDIUM}
      queryError={error}
      queryLoading={loading}
    >
      {devices.length > 1 && <Select id="device" label="Sanntidsmåler" onChange={setDeviceId} options={devices.map(device => {return {label: device?.deviceName, value: device?.deviceId}}) ?? [] } />}

      <Typography
        text={
          "Velg en grense for timesforbruk, så sier vi ifra dersom du nærmer deg underveis i timen (boliger ligger oftest mellom 5 og 10 kWh, så 5 er satt som standard.)"
        }
      />
      {deviceId ?  <StyledCol><AddOrUpdateNotificationSetting deviceId={deviceId} /><HourlyUsage deviceId={deviceId} /><AlertBanner deviceId={deviceId} /></StyledCol>: <Loader />}
    </ScreenWrapper>
  );
};

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: clamp(150px, 70vh, 650px);
  margin-bottom: 2rem;
`