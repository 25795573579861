export enum IconType {
  BACK = "BACK",
  CHECK = "CHECK",
  CLOSE = "CLOSE",
  DELETE = "DELETE",
  EDIT = "EDIT",
  EXTERNAL_LINK="EXTERNAL_LINK",
  PLUS = "PLUS",
  SETTINGS = "SETTINGS",
}
