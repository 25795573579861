import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { Loader } from "../components";
import { NoHarkUnit } from "./no-auth";

export interface AuthContextValues {
  accessToken: string;
}

const AuthContext = createContext<AuthContextValues | undefined>(undefined);

export const AuthProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [firstRender, setFirstRender] = useState(true);
  const [accessTokenState, setAccessTokenState] = useLocalStorage<TokenState>(
    "SESSION_TOKEN",
    {
      type: TokenStateType.NOT_SET,
    }
  );

  const location = useLocation();

  useEffect(() => {
    if (firstRender) {
      // Get the session token from the query string
      const params = new URLSearchParams(location.search);
      setValueUnencoded(params, "accessToken");
      setFirstRender(false);
    }
    function setValueUnencoded(url: URLSearchParams, key: string) {
      const entries = Array.from(url.entries());
      let value = "";
      entries.map((item) => {
        if (item[0] === key) {
          [, value] = item;
          return;
        }
      });

      if (value !== "") {
        setAccessTokenState({
          token: value,
          type: TokenStateType.VALID,
        });
      }

      if (value === "") {
        setAccessTokenState({
          type: TokenStateType.INVALID,
        });
      }
    }
  }, [firstRender, location.search, setAccessTokenState]);

  if (!accessTokenState) {
    return <Loader size="FULLSCREEN" />;
  }

  if (accessTokenState.type === TokenStateType.VALID) {
    return (
      <AuthContext.Provider value={{ accessToken: accessTokenState.token }}>
        <>{children}</>
      </AuthContext.Provider>
    );
  }

  if (accessTokenState.type === TokenStateType.INVALID) {
    return <NoHarkUnit />
  }

  return <Loader size="FULLSCREEN" />;
};

export const useAuth = (): AuthContextValues => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be called inside an AuthProvider`);
  }

  return context;
};

enum TokenStateType {
  VALID = "VALID",
  INVALID = "INVALID",
  NOT_SET = "NOT_SET",
}

interface ValidTokenState {
  type: TokenStateType.VALID;
  token: string;
}

interface InvalidTokenState {
  type: TokenStateType.INVALID;
}

interface NotSetTokenState {
  type: TokenStateType.NOT_SET;
}

type TokenState = ValidTokenState | InvalidTokenState | NotSetTokenState;
