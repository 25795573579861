import { Loader as BaseLoader, LoaderProps } from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { useTheme } from "../theme/ThemeProvider";

export const Loader = (props: PropsWithChildren<LoaderProps>): JSX.Element => {
  const theme = useTheme();
  return (
    <BaseLoader
      color={theme.properties.colors.text["500"]}
      {...props}
    />
  );
};

