import { CSSProperties } from "styled-components";

export type LoaderSize = "SMALL" | "MEDIUM" | "FULLSCREEN";

export interface LoaderTypeProps {
  size?: LoaderSize;
  color?: string;
  style?: CSSProperties;
  centerVertically?: boolean;
}

export const loaderSizeToLoaderWidth = (size?: LoaderSize): number => {
  switch (size) {
    case "SMALL":
      // eslint-disable-next-line no-magic-numbers
      return 16;

    default:
      // eslint-disable-next-line no-magic-numbers
      return 32;
  }
};
