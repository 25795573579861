import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import {
  CheckboxListEntry,
  ErrorText,
  Icon,
  IconType,
  Label,
  Loader,
} from "../../components";
import {
  AddBrightNotificationChannelToAlertSettingDocument,
  AddHourlyConsumptionLimitEstimationWarningAlertSettingDocument,
  RemoveNotificationChannelFromAlertSettingDocument,
  UpdateHourlyConsumptionLimitEstimationWarningAlertSettingDocument,
} from "../../generated/graphql/graphql";
import { useAlert } from "../../providers";
import { useTheme } from "../../theme/ThemeProvider";
import { TextColor } from "../../util";

interface AddOrUpdateNotificationSettingProps {
  deviceId: string;
}

export const AddOrUpdateNotificationSetting = ({
  deviceId,
}: AddOrUpdateNotificationSettingProps) => {
  const { properties: theme } = useTheme();
  const {
    alertId: alertSettingId,
    refetch,
    error,
    limitInWatts,
    loading,
    wantsNotification,
  } = useAlert();

  const [isUpdatingLimit, setIsUpdatingLimit] = useState(false);
  const [limitInKwh, setLimitInKwh] = useState<string>(
    limitInWatts ? (limitInWatts / 1000).toString() : "5"
  );
  const [mutationError, setMutationError] = useState<string | undefined>();
  const [mutationLoading, setMutationLoading] = useState<boolean>();

  const [addAlertSetting] = useMutation(
    AddHourlyConsumptionLimitEstimationWarningAlertSettingDocument
  );
  const [updateAlertSetting] = useMutation(
    UpdateHourlyConsumptionLimitEstimationWarningAlertSettingDocument
  );

  const [addBrightNotificationChannel] = useMutation(
    AddBrightNotificationChannelToAlertSettingDocument
  );

  const [removeNotificationChannel] = useMutation(
    RemoveNotificationChannelFromAlertSettingDocument
  );

  const onWantsNotificationClicked = () => {
    if (!wantsNotification) {
      setIsUpdatingLimit(false);
    }
    onFinish(!wantsNotification);
  };

  useEffect(() => {
    setLimitInKwh(limitInWatts ? (limitInWatts / 1000).toString() : "5");
  }, [alertSettingId, limitInWatts]);

  const onFinish = async (wantsPushNotification: boolean): Promise<void> => {
    setMutationError(undefined);
    setMutationLoading(true);

    let alertSettingIdToUse = alertSettingId;

    try {
      const limitInWatts = parseFloat(limitInKwh) * 1000;

      if (!alertSettingId) {
        const result = await addAlertSetting({
          variables: {
            input: {
              deviceId,
              limitInWatts: isNaN(limitInWatts) ? 0 : limitInWatts,
            },
          },
        });

        alertSettingIdToUse =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          result.data!.addHourlyConsumptionLimitEstimationWarningAlertSetting
            .id;
      } else {
        await updateAlertSetting({
          variables: {
            input: {
              alertSettingId,
              limitInWatts: isNaN(limitInWatts) ? 0 : limitInWatts,
            },
          },
        });
      }

      if (!alertSettingIdToUse) {
        setMutationError("Could not find alert setting");
      } else {
        if (wantsPushNotification) {
          await addBrightNotificationChannel({
            variables: {
              input: {
                alertSettingId: alertSettingIdToUse,
              },
            },
          });
        } else {
          await removeNotificationChannel({
            variables: {
              input: {
                alertSettingId: alertSettingIdToUse,
                notificationChannelType: "BRIGHT_NOTIFICATION",
              },
            },
          });
        }
      }
      setMutationLoading(false);
    } catch (err) {
      setMutationLoading(false);
      setMutationError(err?.toString());
    }
    setIsUpdatingLimit(false);
    refetch();
  };

  return (
    <>
      {error && <ErrorText text={error.message} />}

      <CheckboxListEntry
        checked={wantsNotification}
        loading={loading || mutationLoading}
        id="wantsNotification"
        label="Jeg ønsker varsling"
        onClick={onWantsNotificationClicked}
      />

      <Label for="limit" text="Grenseverdi (kWh)" disabled={!wantsNotification}>
        {mutationError && <ErrorText text={mutationError} />}
        <Form
          onSubmit={(event: FormEvent<HTMLElement>): void => {
            event.preventDefault();
          }}
          theme={theme}
          disabled={!wantsNotification || mutationLoading || loading}
        >
          <input
            id="limit"
            className="limit"
            type="number"
            autoFocus={isUpdatingLimit}
            onClick={() => isUpdatingLimit || !wantsNotification ? undefined : setIsUpdatingLimit(true)}
            readOnly={!isUpdatingLimit || mutationLoading || loading}
            value={limitInKwh}
            onChange={(event) => setLimitInKwh(event.target.value)}
            style={{ justifyContent: "center" }}
          />
          {mutationLoading || loading ? (
            <Loader size="SMALL" centerVertically />
          ) : !isUpdatingLimit ? (
            <Icon
              type={IconType.EDIT}
              color={!wantsNotification ? TextColor.LIGHT_TEXT : TextColor.TEXT}
              centerVertically
              onClick={() => !wantsNotification ? undefined : setIsUpdatingLimit(true)}
            />
          ) : (
            <div style={{ display: "flex", gap: "10px" }}>
              <Icon
                type={IconType.CLOSE}
                centerVertically
                color={TextColor.DANGER}
                onClick={() => setIsUpdatingLimit(false)}
              />

              <Icon
                type={IconType.CHECK}
                centerVertically
                color={TextColor.SUCCESS}
                onClick={() => onFinish(wantsNotification)}
              />
            </div>
          )}
        </Form>
      </Label>
    </>
  );
};

export const Form = styled.form<{ error?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.text[100]
      : props.theme.colors.text["200"]};

  padding: 0 10px;
  width: 100%;

  & input {
    border: 0;
    background: transparent;
    font-size: 16px;

    font-weight: bold;
    color: ${(props) =>
    props.disabled
      ? props.theme.colors.text[300]
      : props.theme.colors.text[500]};
    width: 100%;
    height: 40px;

    &:focus {
      outline: none;
      border 2px solid ${({ theme }): string => theme.colors.primary[500]};
    }

    ${(props) =>
    props.disabled &&
      `
        opacity: 0.5;
        cursor: default;
      `}

    &:invalid {
      box-shadow: none;
    }
  }
  display: flex;
`;
