import { useTheme } from "../../../theme/ThemeProvider";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const CheckIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme })
    : theme.properties.colors.text[500];

  return (
    <path
      d="M16 3.99998L13.3333 6.66665L8 12L5.33333 14.6666L2.66667 12L0 9.33331L2.66667 6.66665L5.33333 9.33331L10.6667 3.99998L13.3333 1.33331L16 3.99998Z"
      fill={fill}
    />
  );
};
