import { BrightNotificationChannel } from "../generated/graphql/graphql";
import { AlertSetting, HourlyConsumptionLimitEstimationAlertSetting, NotificationChannel } from "./gqlAlertSettingToAlertSetting";

export const isHourlyConsumptionAlertSetting = (
  alertSetting: AlertSetting
): alertSetting is HourlyConsumptionLimitEstimationAlertSetting => {
  return (
    alertSetting.alertType ===
    AlertType.HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING
  );
};

export const isMyBrightAlert = (
  channel: NotificationChannel,
  userId: string
): channel is BrightNotificationChannel => {
  if (channel.type === "BRIGHT_NOTIFICATION") {
    return channel.forUserWithId === userId;
  }
  return false
};

enum AlertType {
  PRICE_WARNING = "PRICE_WARNING",
  HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING = "HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING",
  ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING = "ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING",
}