import React from "react";
import { LoaderTypeProps, Ripples, Spinner } from "./loaders";

export enum LoaderType {
  RIPPLES,
  SPINNER,
}

export interface LoaderProps extends LoaderTypeProps {
  loaderType?: LoaderType;
}

export const Loader = ({
  color,
  centerVertically = false,
  loaderType,
  style,
  size,
}: LoaderProps): JSX.Element => {
  switch (loaderType) {
    case LoaderType.RIPPLES:
      return <Ripples color={color} style={style} size={size} centerVertically={centerVertically} />;
    case LoaderType.SPINNER:
    default:
      return <Spinner color={color} style={style} size={size} centerVertically={centerVertically} />;
  }
};
