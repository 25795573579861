import { Select as BaseSelect, Option } from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { useTheme } from "../theme/ThemeProvider";

interface SelectProps {
  id: string;
  focus?: boolean;
  disabled?: boolean;
  label?: string;
  options: Option[]
  onChange: (newValue: string) => void;
}

export const Select = ({
  id,
  focus,
  disabled,
  label,
  options,
  onChange,
}: PropsWithChildren<SelectProps>): JSX.Element => {
  const {properties: theme} = useTheme();
  return (
    <BaseSelect
      id={id}
      options={options}
      label={{
        style: { color: theme.colors.text["500"], fontSize: 14, fontWeight: "900" },
        text: label,
      }}
      optionsStyle={{fontSize: 16}}
      focus={focus}
      disabled={disabled}
      style={{
        borderColor: theme.colors.text["200"],
        borderRadius: "4px",
        borderWidth: 1,
        color: disabled ? theme.colors.text["300"] : theme.colors.text["500"],
        fontSize: "16px",
        height: "40px",
        padding: "8px",
      }}
      onChange={onChange}
    />
  );
};
