import { PropsWithChildren, createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { useLocalStorage } from "react-use";
import { DarkTheme } from "./DarkTheme";
import { HelgelandTheme } from "./HelgelandTheme";
import { LightTheme } from "./LightTheme";
import { Theme } from "./ThemeType";
import { TinnTheme } from "./TinnTheme";

const DEFAULT_THEME = HelgelandTheme;

const ThemeContext = createContext<Theme | undefined>(undefined);

export const ThemeProvider = (
  props: PropsWithChildren<unknown>
): JSX.Element => {
  const [theme, setTheme] = useLocalStorage<Theme>("THEME");

  const query = useQuery();
  useEffect(() => {
    const queryTheme = query.get("theme");
    if (!queryTheme) {
      return;
    }

    if (queryTheme === "dark") {
      setTheme(DarkTheme);
    } else if (queryTheme === "helgeland") {
      setTheme(HelgelandTheme);
    } else if (queryTheme === "tinn") {
      setTheme(TinnTheme);
    } else if (queryTheme === "light") {
      setTheme(LightTheme); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeContext.Provider value={theme  || DEFAULT_THEME}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export function useTheme(): Theme {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be called within a <ThemeProvider>");
  }
  return context;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}