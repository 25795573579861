import { ApolloError } from "@apollo/client";
import { Col, Loader, Row, Spacing, TextAlign, TypographyVariant, VerticalAlignment } from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { ErrorText, Typography } from "../components";

interface ScreenWrapperProps {
  queryLoading?: boolean;
  queryError?: ApolloError;
  childSpacing?: Spacing;
}

export const ScreenWrapper = ({
  queryLoading,
  queryError,
  childSpacing = Spacing.TINY,
  children,
}: PropsWithChildren<ScreenWrapperProps>): JSX.Element => {
  return (
    <Col $childSpacing={childSpacing} style={{ height: "100%", width: "100%" }}>
      {queryLoading ? (
        <Loader size="FULLSCREEN" />
      ) : queryError ? (
        <ErrorText text={queryError.toString()} />
      ) : (
        children
      )}
    </Col>
  );
};

interface BaseHeaderProps {
  title: string | JSX.Element;
  hasBackButton?: boolean;
  onBackClick?: () => void;
  rightButton?: JSX.Element | string;
}

export const BaseHeader = ({
  title,
}: BaseHeaderProps): JSX.Element => {
  const styledTitle =
    typeof title === "string" ? (
      <Typography
        variant={TypographyVariant.HEADER}
        textAlign={TextAlign.CENTER}
        text={title}
        emphasized
      />
    ) : (
      title
    );

  return (
    <Row
      style={{
        justifyContent: "center",
        width: "100%",
      }}
      $verticalAlignChildren={VerticalAlignment.CENTER}
    >
      {styledTitle}
    </Row>
  );
};
