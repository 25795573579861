import { ApolloError, useQuery } from "@apollo/client";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { AlertSetting as GqlAlertSetting, MeDocument } from "../generated/graphql/graphql";
import { AlertSetting, HourlyConsumptionLimitEstimationAlertSetting, gqlAlertSettingToAlertSetting, isHourlyConsumptionAlertSetting, isMyBrightAlert } from "../util";
import { useDevice } from "./DeviceProvider";

export interface AlertContextValues {
  alertId?: string;
  error?: ApolloError;
  wantsNotification: boolean;
  limitInWatts?: number;
  loading?: boolean;
  refetch: () => void;
  setLimitInWatts: (limit: number) => void;
}

const AlertContext = createContext<AlertContextValues | undefined>(undefined);

export const AlertProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const {deviceId} = useDevice();

  const [alertId, setAlertId] = useState<string | undefined>();
  const [limitInWatts, setLimitInWatts] = useState<number>(5000);
  const [wantsNotification, setWantsNotification] = useState(false);
  const [hourlyConsumptionAlertSettings, setHourlyConsumptionAlertSettings] = useState<HourlyConsumptionLimitEstimationAlertSetting[]>();
  const [myBrightAlertSettings, setMyBrightAlertSettings] =
    useState<HourlyConsumptionLimitEstimationAlertSetting[]>();

  const { loading, error, refetch } = useQuery(MeDocument, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const hourlyConsumptionAlertSettings = data.me.alertSettings
        ?.map((alertSetting) =>
          gqlAlertSettingToAlertSetting(alertSetting as GqlAlertSetting)
        )
        ?.filter(isHourlyConsumptionAlertSetting);

      const myBrightChannelAlertSettings: HourlyConsumptionLimitEstimationAlertSetting[] = []
      
      hourlyConsumptionAlertSettings?.map((alertSetting) => {
        if (alertSetting.notificationChannels.map((nc) => isMyBrightAlert(nc, data.me.id)).includes(true)) {
          return myBrightChannelAlertSettings.push(alertSetting)
        } 
      });

      setHourlyConsumptionAlertSettings(hourlyConsumptionAlertSettings)
      setMyBrightAlertSettings(myBrightChannelAlertSettings);
    },
  });


  useEffect(() => {
    const brightAlertSettings = myBrightAlertSettings?.filter(
      (alertSetting) => alertSetting.deviceId === deviceId
    );
    let alertSettingToUse: AlertSetting | undefined;
    if (brightAlertSettings && brightAlertSettings.length > 0) {
      setWantsNotification(true);
      [alertSettingToUse] = brightAlertSettings;
    }
    else {
      alertSettingToUse = hourlyConsumptionAlertSettings?.filter(
        (alertSetting) => alertSetting.deviceId === deviceId
      )[0];
      setWantsNotification(false);
    }

    if (alertSettingToUse) {
      setAlertId(alertSettingToUse.id);
      setLimitInWatts((alertSettingToUse.limitInWatts));
    } else {
      setAlertId(undefined);
      setLimitInWatts(5000);
    }
  }, [deviceId, myBrightAlertSettings, hourlyConsumptionAlertSettings]);

  const reload = async () => {
    await refetch();
  };

  return (
    <AlertContext.Provider value={{ alertId, error, limitInWatts, loading, refetch: reload, setLimitInWatts, wantsNotification }}>
      <>{children}</>
    </AlertContext.Provider>
  );
};

export const useAlert = (): AlertContextValues => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error(`useAlert must be called inside an AlertProvider`);
  }

  return context;
};

