import { useQuery } from "@apollo/client";
import { TypographyVariant } from "@hark-tech/components";
import { DateTime } from "luxon";
import { useState } from "react";
import styled from "styled-components";
import { Typography } from "../../components";
import { AlertEventsOfChannelTypeDocument, HourlyConsumptionWarningEvent } from "../../generated/graphql/graphql";
import { useTheme } from "../../theme";

interface AlertBannerProps {
  deviceId: string;
}

export const AlertBanner = ({ deviceId }: AlertBannerProps) => {
  const theme = useTheme();
  const [alertEvent, setAlertEvent] = useState<HourlyConsumptionWarningEvent>();

  const { loading, error } = useQuery(AlertEventsOfChannelTypeDocument, {
    onCompleted: (data) => {
      const events = data.alertEventsOfChannelType.events.filter(event => event.__typename === "HourlyConsumptionWarningEvent");
      setAlertEvent(events[events.length - 1] as HourlyConsumptionWarningEvent)
    },
    variables: {
      input: {
        deviceId,
        notificationChannelType: "BRIGHT_NOTIFICATION"
      }
    },
  });


  if (loading || !!error || !alertEvent) { 
    return <div />;
  }

  const { hour, minute, day, month } = DateTime.fromISO(alertEvent.at);
  const formattedDay = formatDate(day, month);

  const warningText = alertEvent.consumptionThisFarInTheCurrentHourInWatts < alertEvent.limitInWatts ? "I fare for å gå over valgt grense" : "Du har passert grensen";
  const text = `${formattedDay}, kl. ${formatTime(hour)}:${formatTime(minute)}. ${warningText} (${(alertEvent.limitInWatts/1000).toFixed(2)}kWh) – ${(alertEvent.consumptionThisFarInTheCurrentHourInWatts/1000).toFixed(2)} kWh målt halvveis i timen`

  return (
    <Banner theme={theme.properties}><Typography text={text} variant={TypographyVariant.PARAGRAPH} style={{color: theme.properties.colors.modalBackground["500"]}}/></Banner>
  )
};

const formatTime = (time: number): string => {
  return time < 10 ? `0${time}` : time.toString();
}

const formatDate = (day: number, month: number): string => {
  const today = DateTime.now();
  const yesterday = today.minus({days: 1});
  if (today.day === day && today.month === month) {
    return "I dag";
  }
  else if (yesterday.day === day && yesterday.month === month) {
    return "I går";
  }
  return `${formatTime(day)}.${formatTime(month)}`;
}

const Banner = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;

  margin-top: 4px;
  padding: 1rem;

  text-align: center;
  background-color: ${props => props.theme.colors.primary["500"]}; 
`