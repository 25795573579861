import React, { PropsWithChildren, isValidElement } from "react";
import styled from "styled-components";
import { Label, LabelProps } from "./Label";

interface IconProps {
  color?: string;
}

interface CheckboxProps {
  id: string;
  iconSize?: string;
  checked: boolean;
  checkedCheckbox?: JSX.Element | IconProps;
  uncheckedCheckbox?: JSX.Element | IconProps;
  uncheckedOpacity?: boolean;
  label?: LabelProps;
  setChecked: (newValue: boolean) => void;
  required?: boolean;
}

export function Checkbox({
  id,
  iconSize: size,
  checked,
  required,
  checkedCheckbox,
  uncheckedCheckbox,
  uncheckedOpacity = false,
  label,
  setChecked,
  children,
}: PropsWithChildren<CheckboxProps>): JSX.Element {
  const toggle = (): void => {
    setChecked(!checked);
  };

  const checkedIcon: JSX.Element = checkedCheckbox ? (
    "color" in checkedCheckbox ? (
      <Checked color={checkedCheckbox.color} size={size} />
    ) : isValidElement(checkedCheckbox) ? (
      checkedCheckbox
    ) : (
      <Checked size={size} />
    )
  ) : (
    <Checked size={size} />
  );

  const uncheckedIcon: JSX.Element = uncheckedCheckbox ? (
    "color" in uncheckedCheckbox ? (
      <Unchecked color={uncheckedCheckbox.color} size={size} opacity={uncheckedOpacity} />
    ) : isValidElement(uncheckedCheckbox) ? (
      uncheckedCheckbox
    ) : (
      <Unchecked size={size} opacity={uncheckedOpacity}/>
    )
  ) : (
    <Unchecked size={size} opacity={uncheckedOpacity} />
  );

  const checkbox = (
    <CheckboxWrapper>
      <FakeCheckbox onClick={toggle}>
        {checked ? checkedIcon : uncheckedIcon}
      </FakeCheckbox>

      <HiddenCheckbox
        type="checkbox"
        id={id}
        required={required}
        checked={checked}
        onChange={toggle}
      />

      {children}
    </CheckboxWrapper>
  );

  return (
    <CheckboxRow>
      {label ? (
        <Label
          for={id}
          text={label?.text}
          style={label?.style}
          focusStyle={label?.focusStyle}
        >
          {checkbox}
        </Label>
      ) : (
        checkbox
      )}
    </CheckboxRow>
  );
}

const CheckboxRow = styled.div`
  display: grid;
  grid-template-areas: "checkbox label";
  grid-template-columns: auto 1fr;
  width: 100%;

  align-items: center;
  color: black;
  cursor: pointer;
`;

const CheckboxWrapper = styled.div`
  grid-area: checkbox;
`;

// This has the same location as the visible fake checkbox
const HiddenCheckbox = styled.input`
  position: absolute;
  appearance: none;
  left: 0;
  height: 24px;
  width: 24px;
`;

const FakeCheckbox = styled.div`
  // padding: 10px 15px 5px 0;
  padding-right: 8px;
  display: flex;
  justify-content: center;
`;

interface SvgIconProps extends IconProps {
  size?: string;
}

const Checked = ({ color = "#3CD39D", size = "32" }: SvgIconProps): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 32a8 8 0 008-8V8a8 8 0 00-8-8H8a8 8 0 00-8 8v16a8 8 0 008 8h16zm1-20L13 24l-6-6 2.828-2.828L13 18.343l9.172-9.171L25 12z"
        fill={color}
      />
    </svg>
  );
};

interface UncheckedSvgIconProps extends SvgIconProps {
  opacity?: boolean;
}

const Unchecked = ({ color = "#3CD39D", size = "32", opacity = false }: UncheckedSvgIconProps): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
      <rect
        opacity={opacity ? 1 : 0.2}
        x={32}
        y={32}
        width={32}
        height={32}
        rx={8}
        transform="rotate(180 32 32)"
        fill={color}
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={64}
          y1={32}
          x2={32}
          y2={63}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} stopOpacity={opacity ? 1 : 0.3} />
          <stop offset={1} stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  );
};
