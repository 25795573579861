import { useQuery } from "@apollo/client";
import { Spacing } from "@hark-tech/components";
import { useState } from "react";
import styled from "styled-components";
import { ScreenWrapper } from "../../app/ScreenWrapper";
import { Typography } from "../../components";
import { Button, ButtonVariant } from "../../components/Button";
import { Card } from "../../components/Card";
import { Icon, IconType } from "../../components/icon";
import { MeDocument } from "../../generated/graphql/graphql";
import {
  HarkLogo,
  HelgelandLogo,
  ThemeType,
  TinnLogo,
  useTheme,
} from "../../theme";
import { NoHarkUnitSvg } from "./NoHarkUnitSvg";

export const NoHarkUnit = (): JSX.Element => {
  const { properties, orderUrl } = useTheme();

  const [orderUrlToUse, setOrderUrlToUse] = useState(orderUrl ?? "");

  const { loading } = useQuery(MeDocument, {
    onCompleted: (data) => {
      let externalUserId = "";

      data?.me.verifiedIdentities?.map((identity) => {
        if (identity.__typename === "ExternalUserIdentity") {
          externalUserId = identity.externalId;
        }
      });
  
      if (properties.type === ThemeType.HELGELAND) {
        setOrderUrlToUse(orderUrl + externalUserId);
      }
    },
  });

  const logo = () => {
    switch (properties.type) {
      case ThemeType.DARK:
      case ThemeType.LIGHT:
        return HarkLogo();
      case ThemeType.TINN:
        return TinnLogo();
      case ThemeType.HELGELAND:
      default:
        return HelgelandLogo();
    }
  };

  return (
    <ScreenWrapper childSpacing={Spacing.MEDIUM} queryLoading={loading}>
      <Typography
        emphasized
        text="For å aktivere denne tjenesten trenger du en sanntidsmåler. Ta kontakt med oss for å komme i gang."
      />

      {orderUrl && (
        <Button
          buttonVariant={ButtonVariant.PRIMARY}
          onClick={() => (window.location.href = orderUrlToUse)}
          text="Bestill her"
          iconRight={<Icon type={IconType.EXTERNAL_LINK} centerVertically />}
          iconLeft={logo()}
        />
      )}

      <Card>
        <Typography emphasized text="Sanntidsdata" />
        {CardRow("Se ditt strømforbruk live")}
        {CardRow("Oversikt over hjemmets aktivitetsnivå")}
        {CardRow("Undersøk hvilke laster som trekker mye")}
        {CardRow("Spar penger på nettleien")}
      </Card>

      <Card>
        <Typography emphasized text="Varsling" />
        {CardRow("Få beskjed når din grense nærmer seg")}
        {CardRow("Gjør besparende grep i tide")}
        {CardRow("Spar penger på nettleien")}
      </Card>

      <NoHarkUnitSvg />
    </ScreenWrapper>
  );
};

const CardRow = (text: string) => {
  return (
    <StyledCardRow>
      <Icon type={IconType.CHECK} centerVertically />
      <Typography text={text} centerVertically />
    </StyledCardRow>
  );
};

const StyledCardRow = styled.div`
  display: flex;
  height: 20px;
  gap: 8px;
  margin-top: 8px;
  align-items: center;
`;
