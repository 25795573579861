const TinnLogo = (): JSX.Element  => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5403 13.1463C12.2865 16.0836 9.31341 18.6627 7.66565 21.0985C9.67162 18.8776 12.6806 16.6567 14.2925 13.9343C16.8358 9.56419 14.4 8.77613 12.8955 8.66867C14.0059 9.09852 14.7582 10.2806 13.5403 13.1463Z"
        fill="white"
      />
      <path
        d="M14.5074 15.6537C12.1433 18.4119 8.52535 19.8448 7.02087 22.9612C8.88356 20.203 12.1791 19.1642 14.7224 16.7284C18.8059 12.8597 17.3015 11.2119 16.1552 10.5672C16.8 11.4269 16.8358 12.9313 14.5074 15.6537Z"
        fill="white"
      />
      <path
        d="M15.2597 17.1582C12.5015 19.7015 8.84775 20.6687 6.77014 23.7134C9.0985 21.0985 12.3224 20.4896 15.2239 18.3403C19.8448 14.8657 18.806 12.8955 17.9104 12.0717C18.3403 13.0746 18.0179 14.6508 15.2597 17.1582Z"
        fill="white"
      />
      <path
        d="M10.6746 10.7821C11.9284 7.88061 14.9015 5.26569 16.5493 2.82986C14.5433 5.05076 11.5343 7.27166 9.92239 9.99404C7.34329 14.3642 9.77911 15.1523 11.3194 15.2597C10.1731 14.8299 9.4209 13.6478 10.6746 10.7821Z"
        fill="white"
      />
      <path
        d="M9.70745 8.27462C12.0716 5.51642 15.6895 4.08358 17.194 0.967163C15.3313 3.72537 12.0358 4.76418 9.49252 7.2C5.40894 11.0687 6.91342 12.7164 8.05969 13.3612C7.37909 12.5015 7.37909 10.997 9.70745 8.27462Z"
        fill="white"
      />
      <path
        d="M8.9194 6.77016C11.6776 4.22688 15.3313 3.25971 17.409 0.214935C15.0806 2.82986 11.8567 3.43882 8.95522 5.58807C4.33433 9.0627 5.37313 11.0328 6.26866 11.8567C5.83881 10.8537 6.16119 9.27762 8.9194 6.77016Z"
        fill="white"
      />
    </svg>
  );
};

export default TinnLogo