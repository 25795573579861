import styled from "styled-components";
import { useTheme } from "../../theme/ThemeProvider";

export const NoHarkUnitSvg = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Wrapper>
      <svg
        width="360"
        height="80"
        viewBox="0 0 360 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 80H360C360 63.5 328 74 328 56C328 47 322.5 40 312 40C213 40 222.97 0 180.405 0C140.27 0 129.607 35.5 106 35.5C82.3931 35.5 74.5 20.8378 60.5 20.8378C46.5 20.8378 30.5 26 30.5 47C30.5 68 0 52.8243 0 80Z"
          fill="url(#paint0_linear_758_18581)"
        />
        <rect
          x="146"
          y="34"
          width="3"
          height="46"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="74"
          y="53"
          width="3"
          height="27"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="38"
          y="74"
          width="3"
          height="6"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="218"
          y="30"
          width="3"
          height="50"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="182"
          y="16"
          width="3"
          height="64"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="110"
          y="64"
          width="3"
          height="16"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="254"
          y="62"
          width="3"
          height="18"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="290"
          y="61"
          width="3"
          height="19"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="152"
          y="20"
          width="3"
          height="60"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="80"
          y="61"
          width="3"
          height="19"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="44"
          y="70"
          width="3"
          height="10"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="224"
          y="49"
          width="3"
          height="31"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="188"
          y="22"
          width="3"
          height="58"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="116"
          y="60"
          width="3"
          height="20"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="260"
          y="56"
          width="3"
          height="24"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="296"
          y="67"
          width="3"
          height="13"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="158"
          y="25"
          width="3"
          height="55"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="86"
          y="65"
          width="3"
          height="15"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="50"
          y="61"
          width="3"
          height="19"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="230"
          y="56"
          width="3"
          height="24"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="194"
          y="25"
          width="3"
          height="55"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="122"
          y="56"
          width="3"
          height="24"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="266"
          y="59"
          width="3"
          height="21"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="302"
          y="62"
          width="3"
          height="18"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="164"
          y="22"
          width="3"
          height="58"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="92"
          y="68"
          width="3"
          height="12"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="56"
          y="62"
          width="3"
          height="18"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="236"
          y="53"
          width="3"
          height="27"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="200"
          y="34"
          width="3"
          height="46"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="128"
          y="61"
          width="3"
          height="19"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="272"
          y="62"
          width="3"
          height="18"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="308"
          y="70"
          width="3"
          height="10"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="170"
          y="32"
          width="3"
          height="48"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="98"
          y="69"
          width="3"
          height="11"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="62"
          y="53"
          width="3"
          height="27"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="242"
          y="56"
          width="3"
          height="24"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="206"
          y="20"
          width="3"
          height="60"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="134"
          y="50"
          width="3"
          height="30"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="278"
          y="59"
          width="3"
          height="21"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="314"
          y="59"
          width="3"
          height="21"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="176"
          y="25"
          width="3"
          height="55"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="104"
          y="62"
          width="3"
          height="18"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="68"
          y="56"
          width="3"
          height="24"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="248"
          y="61"
          width="3"
          height="19"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="212"
          y="25"
          width="3"
          height="55"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="140"
          y="47"
          width="3"
          height="33"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="284"
          y="56"
          width="3"
          height="24"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="320"
          y="68"
          width="3"
          height="12"
          rx="1.5"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M117 28H245"
          stroke={theme.properties.colors.primary[500]}
          strokeWidth="2"
          strokeDasharray="4 2"
        />
        <rect
          x="241"
          y="33.9027"
          width="27.3648"
          height="3.90926"
          rx="1.95463"
          fill={theme.properties.colors.primary[500]}
        />
        <rect
          x="252.728"
          y="16.3111"
          width="3.90925"
          height="3.90926"
          rx="1.35974"
          transform="rotate(-90 252.728 16.3111)"
          fill={theme.properties.colors.primary[500]}
        />
        <path
          d="M244.91 24.1294C244.91 18.7319 249.285 14.3563 254.683 14.3563C260.08 14.3563 264.456 18.7319 264.456 24.1294V33.9026H244.91V24.1294Z"
          fill={theme.properties.colors.primary[500]}
        />
        <path
          d="M258.508 39.7666C258.508 41.9256 256.758 43.6758 254.599 43.6758C252.44 43.6758 250.69 41.9256 250.69 39.7666L258.508 39.7666Z"
          fill={theme.properties.colors.primary[500]}
        />
        <path
          d="M112.747 13L99.1604 13C96.8663 13 95 14.8662 95 17.1603L95 40.1136C95 42.4077 96.8663 44.2739 99.1604 44.2739L112.747 44.2739C115.041 44.2739 116.907 42.4077 116.907 40.1136L116.907 17.1603C116.907 14.8662 115.053 13 112.747 13ZM110.833 21.1067C109.716 21.1067 108.8 20.2033 108.8 19.0741C108.8 17.9449 109.704 17.0415 110.833 17.0415C111.95 17.0415 112.866 17.9449 112.866 19.0741C112.866 20.2033 111.95 21.1067 110.833 21.1067Z"
          fill={theme.properties.colors.primary[500]}
        />
        <path
          d="M254.466 45.2856V47.6428V49.9999"
          stroke="white"
          strokeOpacity="0.5"
          strokeWidth="2"
        />
        <path
          d="M258.201 43.738L259.867 45.4047L261.534 47.0714"
          stroke="white"
          strokeOpacity="0.5"
          strokeWidth="2"
        />
        <path
          d="M244.466 40H249.18M259.752 40H264.466"
          stroke="white"
          strokeOpacity="0.5"
          strokeWidth="2"
        />
        <path
          d="M250.728 43.7382L249.061 45.4049L247.395 47.0715"
          stroke="white"
          strokeOpacity="0.5"
          strokeWidth="2"
        />
        <defs>
          <linearGradient
            id="paint0_linear_758_18581"
            x1="0"
            y1="80.027"
            x2="359.775"
            y2="80.027"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={theme.properties.colors.secondary[500]} />
            <stop offset="1" stopColor={theme.properties.colors.danger[500]} />
          </linearGradient>
        </defs>
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  left: calc(calc(100vw - 360px) / 2);
`;
