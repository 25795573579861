/* eslint-disable @typescript-eslint/naming-convention */
import { Theme, ThemeType } from "./ThemeType";

export const TinnTheme: Theme = {
  borderRadius: "8px",
  numberOfHours: 12,
  orderUrl: "https://www.tinnenergifiber.no/sanntidsmaler/",
  properties: {
    colors: {
      background: {
        "300": "rgba(0, 0, 0, 0.15)",
        "500": "rgba(0, 0, 0, 0.05)",
      },

      danger: {
        "300": "rgba(226, 132, 118, 1)",
        "500": "rgba(253,155,118, 1)",
      },

      modalBackground: {
        "500": "#ffffff",
      },

      primary: {
        "500": "rgba(32, 43, 75, 1)",
      },

      secondary: {
        "500": "rgba(226, 212, 191, 1)",
      },

      success: {
        "300": "rgba(60, 211, 157, 0.5)",
        "500": "rgba(60, 211, 157, 1)",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "900": "#1D8969",
      },

      text: {
        "100": "rgba(68, 68, 68, 0.0375)",
        "200": "rgba(68, 68, 68, 0.15)",
        "300": "rgba(68, 68, 68, 0.5)",
        "500": "rgba(68, 68, 68, 1)",
      },

      warning: {
        "500": "rgba(255,231,171,1)",
      },
    },
    type: ThemeType.TINN,
  },
};
