import { CSSProperties } from "react";
import { TextColor } from "../../util";
import { IconType } from "./IconType";
import {
  BackIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon,
  PlusIcon,
  SettingsIcon,
} from "./icons";

export interface SvgIconProps {
  color?: TextColor;
}

export interface IconProps extends SvgIconProps {
  type: IconType;
  size?: number;
}

interface Props extends IconProps {
  centerVertically?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}

export const Icon = ({
  style: customStyle,
  type,
  centerVertically,
  // eslint-disable-next-line no-magic-numbers
  size = 16,
  color,
  onClick,
}: Props): JSX.Element => {
  const style = centerVertically
    ? {
      alignSelf: "center",
      display: "flex",
      ...customStyle,
    }
    : customStyle;
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      {iconTypeToIcon(type, color)}
    </svg>
  );
};

const iconTypeToIcon = (type: IconType, color?: TextColor) => {
  switch (type) {
    case IconType.BACK:
      return <BackIcon color={color} />;

    case IconType.CHECK:
      return <CheckIcon color={color} />;

    case IconType.CLOSE:
      return <CloseIcon color={color} />;

    case IconType.DELETE:
      return <DeleteIcon color={color} />;

    case IconType.EDIT:
      return <EditIcon color={color} />;

    case IconType.EXTERNAL_LINK:
      return <ExternalLinkIcon color={color} />;

    case IconType.PLUS:
      return <PlusIcon color={color} />;

    case IconType.SETTINGS:
      return <SettingsIcon color={color} />;
  }
};
