import { useTheme } from "../../../theme/ThemeProvider";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const ExternalLinkIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme })
    : theme.properties.colors.modalBackground[500];

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C2.89543 2 2 2.89543 2 4V8C2 9.10457 2.89543 10 4 10H8C9.10457 10 10 9.10457 10 8V6H12V8C12 10.2091 10.2091 12 8 12H4C1.79086 12 0 10.2091 0 8V4C0 1.79086 1.79086 0 4 0H6V2H4ZM12 0H7L8.79287 1.79287L4.29289 6.29285C3.90237 6.68337 3.90237 7.31654 4.29289 7.70706C4.68342 8.09758 5.31658 8.09758 5.70711 7.70706L10.2071 3.20708L12 5V0Z"
        fill={fill}
      />
    </svg>
  );
};
