import { styled } from "styled-components";



export const SectionDivider = (): JSX.Element => {
  return (
    <StyledSectionDivider />
  );
};

const StyledSectionDivider = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 10px;

  position: absolute;
  left: 0;

  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
`
