import { Button as BaseButton, LoaderProps } from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { useTheme } from "../theme/ThemeProvider";
import { ErrorText } from "./ErrorText";

interface ButtonProps {
  loading?: boolean;
  error?: string;
  text: string;
  buttonVariant: ButtonVariant;
  disabled?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  loaderStyle?: LoaderProps;
  onClick: () => void;
}

export enum ButtonVariant {
  PRIMARY,
  SECONDARY,
}

export const Button = (props: PropsWithChildren<ButtonProps>): JSX.Element => {
  const theme = useTheme();
  const { buttonVariant, error } = props;

  let backgroundColor = theme.properties.colors.primary["500"];
  let border = `2.5px solid ${theme.properties.colors.text["200"]}`;
  let textColor = theme.properties.colors.modalBackground["500"];
  if (buttonVariant === ButtonVariant.SECONDARY) {
    // eslint-disable-next-line prefer-destructuring
    backgroundColor = theme.properties.colors.secondary["500"];
    textColor = theme.properties.colors.success["900"];
    border = "none";
  }
  const {borderRadius} = theme;
  return (
    <>
      {error && <ErrorText text={error} />}
      <BaseButton
        backgroundColor={backgroundColor}
        textColor={textColor}
        border={border}
        borderRadius={borderRadius}
        size="LARGE"
        fullWidth
        style={{
          display: "grid",
          fontSize: "14px",
          fontWeight: "900",
          gap: "0.5rem",
          gridTemplateColumns: "auto 1fr auto",
          padding: "0px 16px",
          textAlign: "left",
        }}
        {...props}
      />
    </>
  );
};
