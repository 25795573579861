const HarkLogo = (): JSX.Element  => {
  return (
    <svg
      width="42"
      height="48"
      viewBox="0 0 42 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7848 0L41.5696 12.0001L10.3924 18.0002L0 12.0001L20.7848 0Z"
        fill="#4BA9CC"
      />
      <path
        d="M20.7838 35.9999L20.7838 47.9999L41.5684 36L41.5684 12L20.7838 35.9999Z"
        fill="#446AC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.76562e-05 24.0001V24V12L10.3924 17.9999V30L20.7846 35.9999V48L10.3923 42L0 36V24L9.76562e-05 24.0001Z"
        fill="#4285C9"
      />
      <path
        d="M10.3906 30.0002V18.0001L41.5684 12L10.3906 30.0002Z"
        fill="url(#paint0_linear_332_1287)"
      />
      <path
        d="M41.5684 12L10.3906 30.0002L20.7838 36.0002L41.5684 12Z"
        fill="url(#paint1_linear_332_1287)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_332_1287"
          x1="10.3119"
          y1="30.0002"
          x2="41.5057"
          y2="11.7962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA6B0" />
          <stop offset="1" stopColor="#FFB99B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_332_1287"
          x1="41.6632"
          y1="11.9059"
          x2="10.5077"
          y2="31.1079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA6B0" />
          <stop offset="1" stopColor="#FFE7AB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HarkLogo