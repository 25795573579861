import { Label as BaseLabel } from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { useTheme } from "../theme/ThemeProvider";

interface LabelProps {
  text: string;
  for: string;
  disabled?: boolean;
}


export const Label = (props: PropsWithChildren<LabelProps>): JSX.Element => {
  const theme = useTheme();
  return (
    <BaseLabel
      style={{ color: props.disabled ? theme.properties.colors.text[300] : theme.properties.colors.text[500], fontSize: "14px", fontWeight: 900, marginTop: "1rem" }}
      {...props}
    />
  );
};

